// import { IndexPage } from 'components';
//import Layout from 'layouts/Clean';
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { graphql, Link } from 'gatsby';
import {
  SEO,
  LogoPage,
  AgencyDescriptionPage,
  VenicePratoPage,
  ClientListPage,
  FooterPage,
} from 'components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/all';
import { Expo } from 'gsap';
import '../styles/index-page.scss';
import Layout from '../layouts/Clean/index';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const IndexPage = ({ location }) => {
  //JS

  function goToSection(i, options) {
    //console.log(i, window, window.innerHeight);
    if (!window || !window?.innerHeight) return;
    const { innerHeight } = window;

    if (options?.back) {
      gsap.to(window, {
        scrollTo: { y: i * innerHeight - 100, autoKill: false },
        duration: 1,
      });
    }
    gsap.to(window, {
      scrollTo: { y: i * innerHeight, autoKill: false },
      duration: 1,
    });
  }

  useEffect(() => {
    gsap.utils.toArray('.panel').forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: '1px top',
        pin: true,
        pinSpacing: false,
        //snap: true,
        scrub: true,
        // markers: true,
        onEnter: () => goToSection(i + 1),
      });
    });
  }, []);

  return (
    <>
      <Layout location={location}>
        <SEO
          keywords={['TDVB Group']}
          title="TDVB Group - The digital communication network"
          titleTemplate="%s"
          //description="Index di un nuovo sito di trame digitali"
        />
        <section class="panel blue">
          <LogoPage />
        </section>
        <section class="panel orange">
          <AgencyDescriptionPage />
        </section>
        <section class="panel red">
          <ClientListPage />
        </section>
        <section class="panel purple">
          <VenicePratoPage />
        </section>
        {/* <section class="panel orange"></section> */}
        <section class="panel green">
          <FooterPage />
        </section>
      </Layout>
    </>
  );
};
export default IndexPage;
